<template>
    <div>
        <card-header title="Edit Add-On" icon="fa-pencil"/>

        <card-body>
            <card-list>
                <registration-list-item :card="card" :registration="registration"/>
            </card-list>
            <addon-form :addon="addon" :template="template"/>
        </card-body>

        <card-footer class="pt-3 pb-3">
            <div class="columns">
                <div class="column">
                    <b-button type="is-warning is-outlined" size="is-small" @click="deleteAddon"><i class="far fa-trash-alt"></i><span class="ml-2" v-if="confirmDelete">Delete Add-On?</span></b-button>
                </div>
                <div class="column has-text-right">
                    <b-button type="is-primary" size="is-small" @click="submit"><i class="fas fa-check mr-2"></i>Save Add-On</b-button>
                </div>
            </div>
        </card-footer>
    </div>
</template>

<script>
    import CardHeader from "../../TIER/components/CardHeader";
    import CardList from "../../TIER/components/CardList";
    import CardToolbar from "../../TIER/components/CardToolbar";
    import SearchListItem from "../../TIER/components/SearchListItem";
    import SubheaderListItem from "../../TIER/components/SubheaderListItem";
    import SeparatorListItem from "../../TIER/components/SeparatorListItem";
    import NavigationListItem from "../../TIER/components/NavigationListItem";
    import TripListItem from "../../components/TripListItem";

    import {client as http} from '../../http_client';
    import CardBody from "../../TIER/components/CardBody";
    import DataRow from "../../TIER/components/DataRow";
    import DayListItem from "../../components/DayListItem";
    import EventListItem from "../../components/EventListItem";
    import ContainerListItem from "../../TIER/components/ContainerListItem";
    import AddonForm from "./AddonForm";
    import CardFooter from "../../TIER/components/CardFooter";
    import RegistrationListItem from "@/components/RegistrationListItem";

    import async from "async";

    export default {
        name: "Users",
        props: ['card', 'child', 'props'],
        components: {RegistrationListItem, CardFooter, AddonForm, ContainerListItem, EventListItem, DayListItem, DataRow, CardBody, TripListItem, NavigationListItem, SeparatorListItem, SubheaderListItem, SearchListItem, CardToolbar, CardList, CardHeader},
        data: function() {
            return {
                addon: {},
                template: {},
                trip: {},
                registration: {
                    trip: {},
                    user: {},
                    tasks: {},
                    addons: [],
                    flights: []
                },
                confirmDelete: false,
                dirty: null
            }
        },
        methods: {
            loadAddon: function() {
                this.$emit('loading', true);
                async.series([
                    (cb) => {
                        http.get('/api/registrations/' + this.props.registrationId + '/addons/' + this.props.addonId, {force: true}).then(response => {
                            this.addon = response.data;
                            cb();
                        });
                    },
                    (cb) => {
                        http.get('/api/registrations/' + this.props.registrationId, {}).then(response => {
                            this.registration = response.data;
                            cb();
                        });
                    },
                    (cb) => {
                        http.get('/api/addons/' + this.addon.addon_id, {}).then(response => {
                            this.template = response.data;
                            cb();
                        });
                    }
                ], err => {
                    this.$emit('loading', false);
                });
            },
            submit: function() {
                this.$emit('loading', true);
                http.put('/api/registrations/' + this.props.registrationId + '/addons/' + this.props.addonId, {
                    override_title: this.addon.override_title,
                    override_price: this.addon.override_price,
                    override_price_double: this.addon.override_price_double,
                    task_complete: this.addon.task_complete,
                    task_assigned_id: this.addon.task_assigned_id,
                    task_notes: this.addon.task_notes,
                    task_payment: this.addon.task_payment,
                    task_status: this.addon.task_status
                }, {force: true}).then(response => {
                    this.$reloadCard('addons', {registrationId: this.addon.registration_id});
                    this.$reloadCard('registration', {registrationId: this.addon.registration_id});
                    this.$reloadCard('registrations', {});
                    this.$saveAlert(false);
                    this.$closeCard(this.card);
                }).catch(err => {

                });
            },
            deleteAddon() {
                if (this.confirmDelete) {
                    this.$emit('loading', true);
                    http.delete('/api/registrations/' + this.props.registrationId + '/addons/' + this.props.addonId).then(response => {
                        this.$reloadCard('addons', {registrationId: this.addon.registration_id});
                        this.$reloadCard('registration', {registrationId: this.addon.registration_id});
                        this.$reloadCard('registrations', {});
                        this.$saveAlert(false);
                        this.$closeCard(this.card);
                    }).catch(err => {

                    });
                } else {
                    this.confirmDelete = true;
                    setTimeout(() => {
                        this.confirmDelete = false;
                    }, 3000);
                }
            }
        },
        watch: {
            'props.addonId': function () {
                this.dirty = null;
                this.loadAddon();
            },
            'addon': {
                deep: true,
                handler: function () {
                    this.dirty = (this.dirty !== null);
                    if (!this.$TIER.saveAlert.active && this.dirty)
                        this.$saveAlert(true, 'Are you done editing this add-on? Unsaved changes will be lost.');
                }
            }
        },
        mounted() {
            this.loadAddon();
        }
    };
</script>

<style lang="scss" scoped>

</style>
